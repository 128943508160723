import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import ExternalLink from "../components/external-link"

const NationalStrategy = ({location}) => {

  return (
    <Layout pageTitle="National Strategy" pathName={location.pathname}>
      <SEO title="National Strategy" />
      <Content>
        <p>Cargo theft is a nationwide issue with a significant economic impact on the U.S. economy and its national security. Cargo crime accounts for an estimated direct merchandise loss of $10 to $25 billion per year in the United States.</p>

        <p>The creation of the Cargo Theft U.C.R. code has been implemented by the FBI. The FBI is reaching out to state U.C.R. programs and law enforcement agencies to identify obstacles to reporting valid cargo theft data. It is imperative that the FBI collect this U.C.R. data from each state and U.S. territory.</p>

        <p>This National Cargo Theft Prevention Initiative addresses the need for task force structuring and funding, a national threat assessment, and a nationwide cargo theft information and intelligence sharing system.</p>

        <p>The national strategy recommends that the U.S. Congress begin hearings concerning the issue of cargo theft and its threat to the economy and security interests of the United States, and that Congress adopt legislation implementing the recommendations set forth in the national strategy.</p>

        <p>The national strategy also recommends the adoption and implementation of a multi-level public awareness campaign designed to increase the understanding of the threat cargo theft poses to the nation’s economy and to the public safety and health.</p>

        <ExternalLink url="https://www.nicb.org/media/1474/download" title="Extracted from the National Commercial Vehicle and Cargo Theft Prevention Initiative"/><br />
      </Content>
    </Layout>
  )
}

export default NationalStrategy
